import React from "react"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import Head from "../components/Head"
import "../styles/pedidos.css"

const pedidos = props => (
  <>
    <Head
      description="Te llevaremos el pedido que quieras, un delicioso plato hasta la comodidad de tu casa, no esperes y llamanos a nuestros números"
      title="Domicilios - Asadero RoqueFer"
      keywords="domicilios envios en bogota"
    />
    <Layout>
      <main>
        <Hero />
        <div className="shippings">
          <h2>Domicilios</h2>
          <p>
            Si quieres realizar tu pedido de forma rápida y sencilla, ponemos a
            tu disposición el servicio de pedidos por teléfono.
          </p>
          <i>
            Preciona en cualquiera de los números en pantalla y llamanos
            directamente.
          </i>
          <div className="shippings-contact">
            <a href="tel:(+57)3222048342">
              322-204-8342<span></span>
            </a>
            <a href="tel:(+57)3222064029">
              322-206-4029<span></span>
            </a>
            <a href="tel:(+57)3108520109">
              310-852-0109<span></span>
            </a>
            <a href="tel:(+57)3107763124">
              310-776-3124<span></span>
            </a>
            <a href="tel:(+57)3224067052">
              322-406-7052<span></span>
            </a>
          </div>
          <p className="title">Horario de atención</p>
          <p>11:00 a.m a 10:00 p.m de lunes a domingo</p>
        </div>
      </main>
    </Layout>
  </>
)

export default pedidos
